import qlik from '../orders/qlik';
import {dimensions, measures} from './qlik/qlik';
export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Chauffeur',
        value: 'driver',
        children: [
          {
            label: 'Naam',
            value: 'vD_Driver.Name',
            qlik: dimensions.driver.name,
            new_value: "dimensions.debtor.name",
          },
          {
            label: 'Nummer',
            value: 'vD_Driver.Number',
            qlik: dimensions.driver.number,
            new_value: "dimensions.debtor.number"
          }
        ]
      },
      {
        label: 'Auto',
        value: 'vehicle',
        children: [
          {
            label: 'Naam',
            value: 'vD_Vehicle.Number',
            qlik: dimensions.vehicle.number,
            new_value: "dimensions.debtor.number"

          },
          {
            label: 'Nummer',
            value: 'vD_Vehicle.Name',
            qlik: dimensions.vehicle.name,
            new_value: "dimensions.debtor.name"
          }
        ]
      },
      {
        label: 'Crediteur',
        value: 'crediteur',
        children: [
          {
            label: 'Naam',
            value: 'vD_Trip.CreditorName',
            qlik: dimensions.charter.name,
            new_value: "dimensions.charter.name"

          },
          {
            label: 'Nummer',
            value: 'vD_Trip.CreditorNumber',
            qlik: dimensions.charter.number,
            new_value: "dimensions.charter.number"
          }
        ]
      },
      {
        label: 'Trailer',
        value: 'trailer',
        children: [
          {
            label: 'Nummer',
            value: 'vD_Trip.TrailerNumber',
            qlik: dimensions.trailer.number,
            new_value: "dimensions.trailer.number"

          },
        ]
      },
      {
        label: 'Rit',
        value: 'rit',
        children: [
          {
            value: 'vD_Trip.TripNumber',
            label: 'Technisch Trip Nummer',
            qlik: dimensions.trip.number,
            new_value: "dimensions.trip.number"
          },
        ]
      },
      {
        label: 'Start Locatie',
        value: 'laadlocatie',
        children: [
          {
            value: 'vD_Trip.StartLocation.ID',
            label: 'ID',
            qlik: dimensions.startlocation.id,
            new_value: "dimensions.startlocation.id"
          },
          {
            value: 'vD_Trip.StartLocation.Name',
            label: 'Naam',
            qlik: dimensions.startlocation.name,
            new_value: "dimensions.startlocation.name"
          },
          {
            value: 'vD_Trip.StartLocation.ZIPcode',
            label: 'Postcode',
            qlik: dimensions.startlocation.zipcode,
            new_value: "dimensions.startlocation.zipcode"
          },
          {
            value: 'vD_Trip.StartLocation.City',
            label: 'Plaats',
            qlik: dimensions.startlocation.city,
            new_value: "dimensions.startlocation.city"
          },
          {
            value: 'vD_Trip.StartLocation.Country',
            label: 'Land',
            qlik: dimensions.startlocation.country,
            new_value: "dimensions.startlocation.country"
          }
        ]
      },
      {
        label: 'Eind Locatie',
        value: 'loslocatie',
        children: [
          {
            value: 'vD_Trip.EndLocation.ID',
            label: 'ID',
            qlik: dimensions.endlocation.id,
            new_value: "dimensions.endlocation.id"
          },
          {
            value: 'vD_Trip.EndLocation.Name',
            label: 'Naam',
            qlik: dimensions.endlocation.name,
            new_value: "dimensions.endlocation.name"
          },
          {
            value: 'vD_Trip.EndLocation.ZIPcode',
            label: 'Postcode',
            qlik: dimensions.endlocation.zipcode,
            new_value: "dimensions.endlocation.zipcode"
          },
          {
            value: 'vD_Trip.EndLocation.City',
            label: 'Plaats',
            qlik: dimensions.endlocation.city,
            new_value: "dimensions.endlocation.city"
          },
          {
            value: 'vD_Trip.EndLocation.Country',
            label: 'Land',
            qlik: dimensions.endlocation.country,
            new_value: "dimensions.endlocation.country"
          }
        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            value: 'vD_Year',
            label: 'Jaar',
            qlik: dimensions.period.year,
            new_value: "dimensions.period.year"
          },
          {
            value: 'vD_Quarter',
            label: 'Kwartaal',
            qlik: dimensions.period.quarter,
            new_value: "dimensions.period.quarter"
          },
          {
            value: 'vD_Month',
            label: 'Maand',
            qlik: dimensions.period.month,
            new_value: "dimensions.period.month"
          },
          {
            value: 'vD_Week',
            label: 'Week',
            qlik: dimensions.period.week,
            new_value: "dimensions.period.week"
          },
          {
            value: 'vD_Date',
            label: 'Datum',
            qlik: dimensions.period.date,
            new_value: "dimensions.period.date"
          },
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            value: 'vE_TripOmzet',
            label: 'Totaal',
            qlik: measures.omzet.totaal,
            new_value: "measures.omzet.totaal"
          },
          {
            qlik: measures.omzet.freight,
            value: 'vE_TripOmzetFreight',
            label: 'Vracht'
          },
          {
            qlik: measures.omzet.fuel,
            value: 'vE_TripOmzetFuel',
            label: 'Brandstof'
          },
          {
            qlik: measures.omzet.other,
            value: 'vE_TripOmzetOther',
            label: 'Overig'
          }
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            value: 'vE_TripKosten',
            label: 'Totaal',
            qlik: measures.kosten.totaal,
            new_value: "measures.kosten.totaal"
          },
          {
            value: 'vE_TripKostenCharter',
            label: 'Derden',
            qlik: measures.kosten.charter,
            new_value: "measures.kosten.charter"
          },
          {
            value: 'vE_TripKostenTol',
            label: 'Tol',
            qlik: measures.kosten.toll,
            new_value: "measures.kosten.toll"
          },
          {
            value: 'vE_TripKostenWarehouse',
            label: 'Warehouse',
            qlik: measures.kosten.warehouse,
            new_value: "measures.kosten.warehouse"
          },
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            value: 'vE_TripAantalOrders',
            label: 'Order',
            qlik: measures.aantal.orders,
            new_value: "measures.aantal.orders"
          },
          {
            value: 'vE_TripAantalRitten',
            label: 'Ritten',
            qlik: measures.aantal.ritten,
            new_value: "measures.aantal.ritten"
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            value: 'vE_KPI_TripGoedkeur',
            label: 'OK',
            qlik: measures.kpi.goedkeur,
            new_value: "measures.kpi.goedkeur"
          },

          {
            value: 'vE_KPI_TripNormMarginPercent',
            label: 'Norm Marge %',
            qlik: measures.kpi.normmarginpercent,
            new_value: "measures.kpi.normmarginpercent"
          },
          {
            value: 'vE_KPI_TripNormMarginOverOmzet',
            label: 'Norm Marge',
            qlik: measures.kpi.normmarginomzet,
            new_value: "measures.kpi.normmarginomzet"
          },
        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            value: 'vE_KPI_TripOmzetPerUur',
            label: 'Omzet / Uur',
            qlik: measures.own.omzetperuur,
            new_value: "measures.own.omzetperuur"
          },
          {
            value: 'vE_KPI_TripOmzetPerUurExDot',
            label: 'Omzet / Uur zonder DOT',
            qlik: measures.own.omzetperuurexfuel,
            new_value: "measures.own.omzetperuurexfuel"
          },
          {
            value: 'vE_KPI_TripBezetting',
            label: 'Bezetting',
            qlik: measures.own.bezetting,
            new_value: "measures.own.bezetting"
          },
          {
            value: 'vE_TripNormTurnoverHour',
            label: 'Norm Omzet / Uur',
            qlik: measures.own.normomzetperuur,
            new_value: "measures.own.normomzetperuur"
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            value: 'vE_KPI_TripTripOutsourced',
            label: '% Uitbesteed',
            qlik: measures.outsourced.percentage,
            new_value: "measures.outsourced.percentage"
          },
          {
            value: 'vE_KPI_TripCharterMarge',
            label: 'Derden Marge',
            qlik: measures.outsourced.marge,
            new_value: "measures.outsourced.marge"
          },
          {
            value: 'vE_TripNormCharter',
            label: 'Norm',
            qlik: measures.outsourced.norm,
            new_value: "measures.outsourced.norm"
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            value: 'vE_TripKM',
            label: 'Totaal',
            qlik: measures.km.km,
            new_value: "measures.km.km"
          },
          {
            value: 'vE_TripKMFull',
            label: 'Beladen',
            qlik: measures.km.kmfull,
            new_value: "measures.km.kmfull"
          },
          {
            value: 'vE_TripKMEmpty',
            label: 'Onbeladen',
            qlik: measures.km.kmempty,
            new_value: "measures.km.kmempty"
          },
          {
            value: 'vE_KPI_TripOmzetPerKM',
            label: 'Omzet / KM',
            qlik: measures.km.omzetperkm,
            new_value: "measures.km.omzetperkm"
          },
          {
            value: 'vE_KPI_TripOmzetPerKMFull',
            label: 'Omzet / beladen KM',
            qlik: measures.km.omzetperkmfull,
            new_value: "measures.km.omzetperkmfull"
          },
          {
            value: 'vE_KPI_TripBelading',
            label: '% Beladen',
            qlik: measures.km.belading,
            new_value: "measures.km.belading"
          },
          {
            value: 'vE_TripNormTurnoverKM',
            label: 'Norm Omzet / KM',
            qlik: measures.km.normomzetperkm,
            new_value: "measures.km.normomzetperkm"
          },
          {
            value: 'vE_TripNormTurnoverKMFull',
            label: 'Norm Omzet / beladen KM',
            qlik: measures.km.normomzetperkmfull,
            new_value: "measures.km.normomzetperkmfull"
          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            value: 'vE_TripUren',
            label: 'Totaal (Σ)',
            qlik: measures.uren.totaal,
            new_value: "measures.uren.totaal"
          },
          {
            value: 'vE_TripUrenLoading',
            label: 'Laden (Σ)',
            qlik: measures.uren.loading,
            new_value: "measures.uren.loading"
          },
          {
            value: 'vE_TripUrenUnloading',
            label: 'Lossen (Σ)',
            qlik: measures.uren.unloading,
            new_value: "measures.uren.unloading"
          },
          {
            value: 'vE_TripUrenDriving',
            label: 'Rijden (Σ)',
            qlik: measures.uren.driving,
            new_value: "measures.uren.driving"
          },
          {
            value: 'vE_TripUrenOther',
            label: 'Overig (Σ)',
            qlik: measures.uren.other,
            new_value: "measures.uren.other"
          },
          {
            value: 'vE_TripUrenAvg',
            label: 'Totaal (avg)',
            qlik: measures.uren.avg_totaal,
            new_value: "measures.uren.avg_totaal"
          },
          {
            value: 'vE_TripUrenAvgLoading',
            label: 'Laden (avg)',
            qlik: measures.uren.avg_loading,
            new_value: "measures.uren.avg_loading"
          },
          {
            value: 'vE_TripUrenAvgUnloading',
            label: 'Lossen (avg)',
            qlik: measures.uren.avg_unloading,
            new_value: "measures.uren.avg_unloading"
          },
          {
            value: 'vE_TripUrenAvgDriving',
            label: 'Rijden (avg)',
            qlik: measures.uren.avg_driving,
            new_value: "measures.uren.avg_driving"
          },
          {
            value: 'vE_TripUrenAvgOther',
            label: 'Overig (avg)',
            qlik: measures.uren.avg_other,
            new_value: "measures.uren.avg_other"     
          },
        ]
      },
      {
        value: 'vergelijkperiode',
        label: 'Voorgaand jaar',
        children: [
          {
            value: 'vE_TripOmzetVorigJaar',
            label: 'Omzet',
            qlik: measures.previousyear.omzet,
            new_value: "measures.previousyear.omzet"     
          },
          {
            value: 'vE_TripAantalOrdersVorigJaar',
            label: 'Order',
            qlik: measures.previousyear.orders,
            new_value: "measures.previousyear.orders"   
          },
          {
            value: 'vE_TripAantalRittenVorigJaar',
            label: 'Ritten',
            qlik: measures.previousyear.ritten,
            new_value: "measures.previousyear.ritten"  
          },
          {
            value: 'vE_KPI_TripGoedkeurVorigJaar',
            label: 'OK',
            qlik: measures.previousyear.goedkeur,
            new_value: "measures.previousyear.goedkeur"  
          },
          {
            value: 'vE_KPI_TripNormMarginPercentVorigJaar',
            label: 'Norm Marge %',
            qlik: measures.previousyear.normmarge,
            new_value: "measures.previousyear.normmarge"  
          },
          {
            value: 'vE_KPI_TripNormMarginOverOmzetVorigJaar',
            label: 'Norm Marge',
            qlik: measures.previousyear.normmargeomzet,
            new_value: "measures.previousyear.normmargeomzet"  
          },
          {
            value: 'vE_KPI_TripOmzetPerUurVorigJaar',
            label: 'Omzet / Uur',
            qlik: measures.previousyear.omzetperuur,
            new_value: "measures.previousyear.omzetperuur" 
          },
          {
            value: 'vE_KPI_TripOmzetPerUurCombinatieVolgendeOrderVorigJaar',
            label: 'Omzet / Uur - rondrit',
            qlik: measures.previousyear.volgendeorder,
            new_value: "measures.previousyear.volgendeorder" 
          },
          {
            value: 'vE_KPI_TripTripOutsourcedVorigJaar',
            label: '% Uitbesteed',
            qlik: measures.previousyear.uitbesteed,
            new_value: "measures.previousyear.uitbesteed" 
          },
          {
            value: 'vE_KPI_TripCharterMargeVorigJaar',
            label: 'Derden Marge',
            qlik: measures.previousyear.chargermarge,
            new_value: "measures.previousyear.chargermarge" 
          },

        ],
      },
    ]
  }
];
