const dimensions = {
    customer: {
        name: {
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Order.Customer.Name)]"
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.Customer.Name)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
        },
        number: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Order.Customer.Number)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.Customer.Number)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        type: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Order.Customer.Type)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.Customer.Type)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        }
    },
    debtor: {
      name: {
          "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                  "[$(vD_Order.Debtor.Name)]"
              ],
              "qSortCriterias": [
                  {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                          "qv": ""
                      },
                      "qSortByGreyness": 0
                  }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Order.Debtor.Name)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                  "auto": true,
                  "align": "left"
              },
              "representation": {
                  "type": "text",
                  "urlLabel": ""
              }
          },
          "qNullSuppression": false,
          "qIncludeElemValue": false,
          "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                  "qv": "10"
              },
              "qOtherLimit": {
                  "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                  "qv": ""
              }
          },
          "qShowTotal": false,
          "qShowAll": false,
          "qOtherLabel": {
              "qv": "Overige"
          },
          "qTotalLabel": {
              "qv": ""
          },
          "qCalcCond": {
              "qv": ""
          },
      },
      number: {
          "qLibraryId": "",
          "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                  "[$(vD_Order.Debtor.Number)]"
              ],
              "qFieldLabels": [
                  ""
              ],
              "qSortCriterias": [
                  {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                          "qv": ""
                      },
                      "qSortByGreyness": 0
                  }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Order.Debtor.Number)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                  "auto": true,
                  "align": "left"
              },
              "representation": {
                  "type": "text",
                  "urlLabel": ""
              }
          },
          "qNullSuppression": false,
          "qIncludeElemValue": false,
          "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                  "qv": "10"
              },
              "qOtherLimit": {
                  "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                  "qv": ""
              }
          },
          "qShowTotal": false,
          "qShowAll": false,
          "qOtherLabel": {
              "qv": "Overige"
          },
          "qTotalLabel": {
              "qv": ""
          },
          "qCalcCond": {
              "qv": ""
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCondition": {
              "qCond": {
                  "qv": ""
              },
              "qMsg": {
                  "qv": ""
              }
          }
      },
      type: {
          "qLibraryId": "",
          "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                  "[$(vD_Order.Debtor.Type)]"
              ],
              "qFieldLabels": [
                  ""
              ],
              "qSortCriterias": [
                  {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                          "qv": ""
                      },
                      "qSortByGreyness": 0
                  }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Order.Debtor.Type)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                  "auto": true,
                  "align": "left"
              },
              "representation": {
                  "type": "text",
                  "urlLabel": ""
              }
          },
          "qNullSuppression": false,
          "qIncludeElemValue": false,
          "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                  "qv": "10"
              },
              "qOtherLimit": {
                  "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                  "qv": ""
              }
          },
          "qShowTotal": false,
          "qShowAll": false,
          "qOtherLabel": {
              "qv": "Overige"
          },
          "qTotalLabel": {
              "qv": ""
          },
          "qCalcCond": {
              "qv": ""
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCondition": {
              "qCond": {
                  "qv": ""
              },
              "qMsg": {
                  "qv": ""
              }
          }
      }
  },
    order: {
        number: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Order.OrderNumber)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.OrderNumber)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        dossier: {
          "qLibraryId": "",
          "qDef": {
            "qGrouping": "N",
            "qFieldDefs": [
              "[$(vD_Order.Dossier)]"
            ],
            "qFieldLabels": [
              ""
            ],
            "qSortCriterias": [
              {
                "qSortByState": 0,
                "qSortByFrequency": 0,
                "qSortByNumeric": 1,
                "qSortByAscii": 1,
                "qSortByLoadOrder": 1,
                "qSortByExpression": 0,
                "qExpression": {
                  "qv": ""
                },
                "qSortByGreyness": 0
              }
            ],
            "qNumberPresentations": [],
            "qReverseSort": false,
            "qActiveField": 0,
            "qLabelExpression": "='$(vD_Order.Dossier)'",
            "autoSort": true,
            "othersLabel": "Overige",
            "textAlign": {
              "auto": true,
              "align": "left"
            },
            "representation": {
              "type": "text",
              "urlLabel": ""
            }
          },
          "qNullSuppression": false,
          "qIncludeElemValue": false,
          "qOtherTotalSpec": {
            "qOtherMode": "OTHER_OFF",
            "qOtherCounted": {
              "qv": "10"
            },
            "qOtherLimit": {
              "qv": "0"
            },
            "qOtherLimitMode": "OTHER_GE_LIMIT",
            "qSuppressOther": false,
            "qForceBadValueKeeping": true,
            "qApplyEvenWhenPossiblyWrongResult": true,
            "qGlobalOtherGrouping": false,
            "qOtherCollapseInnerDimensions": false,
            "qOtherSortMode": "OTHER_SORT_DESCENDING",
            "qTotalMode": "TOTAL_OFF",
            "qReferencedExpression": {
              "qv": ""
            }
          },
          "qShowTotal": false,
          "qShowAll": false,
          "qOtherLabel": {
            "qv": "Overige"
          },
          "qTotalLabel": {
            "qv": ""
          },
          "qCalcCond": {
            "qv": ""
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
        ordernumberdisplay: {
          "qLibraryId": "",
          "qDef": {
            "qGrouping": "N",
            "qFieldDefs": [
              "[$(vD_Order.OrderNumberDisplay)]"
            ],
            "qFieldLabels": [
              ""
            ],
            "qSortCriterias": [
              {
                "qSortByState": 0,
                "qSortByFrequency": 0,
                "qSortByNumeric": 1,
                "qSortByAscii": 1,
                "qSortByLoadOrder": 1,
                "qSortByExpression": 0,
                "qExpression": {
                  "qv": ""
                },
                "qSortByGreyness": 0
              }
            ],
            "qNumberPresentations": [],
            "qReverseSort": false,
            "qActiveField": 0,
            "qLabelExpression": "='$(vD_Order.OrderNumberDisplay)'",
            "autoSort": true,
            "othersLabel": "Overige",
            "textAlign": {
              "auto": true,
              "align": "left"
            },
            "representation": {
              "type": "text",
              "urlLabel": ""
            }
          },
          "qNullSuppression": false,
          "qIncludeElemValue": false,
          "qOtherTotalSpec": {
            "qOtherMode": "OTHER_OFF",
            "qOtherCounted": {
              "qv": "10"
            },
            "qOtherLimit": {
              "qv": "0"
            },
            "qOtherLimitMode": "OTHER_GE_LIMIT",
            "qSuppressOther": false,
            "qForceBadValueKeeping": true,
            "qApplyEvenWhenPossiblyWrongResult": true,
            "qGlobalOtherGrouping": false,
            "qOtherCollapseInnerDimensions": false,
            "qOtherSortMode": "OTHER_SORT_DESCENDING",
            "qTotalMode": "TOTAL_OFF",
            "qReferencedExpression": {
              "qv": ""
            }
          },
          "qShowTotal": false,
          "qShowAll": false,
          "qOtherLabel": {
            "qv": "Overige"
          },
          "qTotalLabel": {
            "qv": ""
          },
          "qCalcCond": {
            "qv": ""
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
        systeem: {
          "qLibraryId": "",
          "qDef": {
            "qGrouping": "N",
            "qFieldDefs": [
              "[$(vD_Order.Systeem)]"
            ],
            "qFieldLabels": [
              ""
            ],
            "qSortCriterias": [
              {
                "qSortByState": 0,
                "qSortByFrequency": 0,
                "qSortByNumeric": 1,
                "qSortByAscii": 1,
                "qSortByLoadOrder": 1,
                "qSortByExpression": 0,
                "qExpression": {
                  "qv": ""
                },
                "qSortByGreyness": 0
              }
            ],
            "qNumberPresentations": [],
            "qReverseSort": false,
            "qActiveField": 0,
            "qLabelExpression": "='$(vD_Order.Systeem)'",
            "autoSort": true,
            "othersLabel": "Overige",
            "textAlign": {
              "auto": true,
              "align": "left"
            },
            "representation": {
              "type": "text",
              "urlLabel": ""
            }
          },
          "qNullSuppression": false,
          "qIncludeElemValue": false,
          "qOtherTotalSpec": {
            "qOtherMode": "OTHER_OFF",
            "qOtherCounted": {
              "qv": "10"
            },
            "qOtherLimit": {
              "qv": "0"
            },
            "qOtherLimitMode": "OTHER_GE_LIMIT",
            "qSuppressOther": false,
            "qForceBadValueKeeping": true,
            "qApplyEvenWhenPossiblyWrongResult": true,
            "qGlobalOtherGrouping": false,
            "qOtherCollapseInnerDimensions": false,
            "qOtherSortMode": "OTHER_SORT_DESCENDING",
            "qTotalMode": "TOTAL_OFF",
            "qReferencedExpression": {
              "qv": ""
            }
          },
          "qShowTotal": false,
          "qShowAll": false,
          "qOtherLabel": {
            "qv": "Overige"
          },
          "qTotalLabel": {
            "qv": ""
          },
          "qCalcCond": {
            "qv": ""
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
        bedrijf: {
          "qLibraryId": "",
          "qDef": {
            "qGrouping": "N",
            "qFieldDefs": [
              "[$(vD_Order.Bedrijf)]"
            ],
            "qFieldLabels": [
              ""
            ],
            "qSortCriterias": [
              {
                "qSortByState": 0,
                "qSortByFrequency": 0,
                "qSortByNumeric": 1,
                "qSortByAscii": 1,
                "qSortByLoadOrder": 1,
                "qSortByExpression": 0,
                "qExpression": {
                  "qv": ""
                },
                "qSortByGreyness": 0
              }
            ],
            "qNumberPresentations": [],
            "qReverseSort": false,
            "qActiveField": 0,
            "qLabelExpression": "='$(vD_Order.Bedrijf)'",
            "autoSort": true,
            "othersLabel": "Overige",
            "textAlign": {
              "auto": true,
              "align": "left"
            },
            "representation": {
              "type": "text",
              "urlLabel": ""
            }
          },
          "qNullSuppression": false,
          "qIncludeElemValue": false,
          "qOtherTotalSpec": {
            "qOtherMode": "OTHER_OFF",
            "qOtherCounted": {
              "qv": "10"
            },
            "qOtherLimit": {
              "qv": "0"
            },
            "qOtherLimitMode": "OTHER_GE_LIMIT",
            "qSuppressOther": false,
            "qForceBadValueKeeping": true,
            "qApplyEvenWhenPossiblyWrongResult": true,
            "qGlobalOtherGrouping": false,
            "qOtherCollapseInnerDimensions": false,
            "qOtherSortMode": "OTHER_SORT_DESCENDING",
            "qTotalMode": "TOTAL_OFF",
            "qReferencedExpression": {
              "qv": ""
            }
          },
          "qShowTotal": false,
          "qShowAll": false,
          "qOtherLabel": {
            "qv": "Overige"
          },
          "qTotalLabel": {
            "qv": ""
          },
          "qCalcCond": {
            "qv": ""
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
        companyname: {
          "qLibraryId": "",
          "qDef": {
            "qGrouping": "N",
            "qFieldDefs": [
              "[$(vD_Order.Company.Name)]"
            ],
            "qFieldLabels": [
              ""
            ],
            "qSortCriterias": [
              {
                "qSortByState": 0,
                "qSortByFrequency": 0,
                "qSortByNumeric": 1,
                "qSortByAscii": 1,
                "qSortByLoadOrder": 1,
                "qSortByExpression": 0,
                "qExpression": {
                  "qv": ""
                },
                "qSortByGreyness": 0
              }
            ],
            "qNumberPresentations": [],
            "qReverseSort": false,
            "qActiveField": 0,
            "qLabelExpression": "='$(vD_Order.Company.Name)'",
            "autoSort": true,
            "othersLabel": "Overige",
            "textAlign": {
              "auto": true,
              "align": "left"
            },
            "representation": {
              "type": "text",
              "urlLabel": ""
            }
          },
          "qNullSuppression": false,
          "qIncludeElemValue": false,
          "qOtherTotalSpec": {
            "qOtherMode": "OTHER_OFF",
            "qOtherCounted": {
              "qv": "10"
            },
            "qOtherLimit": {
              "qv": "0"
            },
            "qOtherLimitMode": "OTHER_GE_LIMIT",
            "qSuppressOther": false,
            "qForceBadValueKeeping": true,
            "qApplyEvenWhenPossiblyWrongResult": true,
            "qGlobalOtherGrouping": false,
            "qOtherCollapseInnerDimensions": false,
            "qOtherSortMode": "OTHER_SORT_DESCENDING",
            "qTotalMode": "TOTAL_OFF",
            "qReferencedExpression": {
              "qv": ""
            }
          },
          "qShowTotal": false,
          "qShowAll": false,
          "qOtherLabel": {
            "qv": "Overige"
          },
          "qTotalLabel": {
            "qv": ""
          },
          "qCalcCond": {
            "qv": ""
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
        companycode: {
          "qLibraryId": "",
          "qDef": {
            "qGrouping": "N",
            "qFieldDefs": [
              "[$(vD_Order.Company.Code)]"
            ],
            "qFieldLabels": [
              ""
            ],
            "qSortCriterias": [
              {
                "qSortByState": 0,
                "qSortByFrequency": 0,
                "qSortByNumeric": 1,
                "qSortByAscii": 1,
                "qSortByLoadOrder": 1,
                "qSortByExpression": 0,
                "qExpression": {
                  "qv": ""
                },
                "qSortByGreyness": 0
              }
            ],
            "qNumberPresentations": [],
            "qReverseSort": false,
            "qActiveField": 0,
            "qLabelExpression": "='$(vD_Order.Company.Code)'",
            "autoSort": true,
            "othersLabel": "Overige",
            "textAlign": {
              "auto": true,
              "align": "left"
            },
            "representation": {
              "type": "text",
              "urlLabel": ""
            }
          },
          "qNullSuppression": false,
          "qIncludeElemValue": false,
          "qOtherTotalSpec": {
            "qOtherMode": "OTHER_OFF",
            "qOtherCounted": {
              "qv": "10"
            },
            "qOtherLimit": {
              "qv": "0"
            },
            "qOtherLimitMode": "OTHER_GE_LIMIT",
            "qSuppressOther": false,
            "qForceBadValueKeeping": true,
            "qApplyEvenWhenPossiblyWrongResult": true,
            "qGlobalOtherGrouping": false,
            "qOtherCollapseInnerDimensions": false,
            "qOtherSortMode": "OTHER_SORT_DESCENDING",
            "qTotalMode": "TOTAL_OFF",
            "qReferencedExpression": {
              "qv": ""
            }
          },
          "qShowTotal": false,
          "qShowAll": false,
          "qOtherLabel": {
            "qv": "Overige"
          },
          "qTotalLabel": {
            "qv": ""
          },
          "qCalcCond": {
            "qv": ""
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
        reference: {
          "qLibraryId": "",
          "qDef": {
            "qGrouping": "N",
            "qFieldDefs": [
              "[$(vD_Order.Reference)]"
            ],
            "qFieldLabels": [
              ""
            ],
            "qSortCriterias": [
              {
                "qSortByState": 0,
                "qSortByFrequency": 0,
                "qSortByNumeric": 1,
                "qSortByAscii": 1,
                "qSortByLoadOrder": 1,
                "qSortByExpression": 0,
                "qExpression": {
                  "qv": ""
                },
                "qSortByGreyness": 0
              }
            ],
            "qNumberPresentations": [],
            "qReverseSort": false,
            "qActiveField": 0,
            "qLabelExpression": "='$(vD_Order.Reference)'",
            "autoSort": true,
            "othersLabel": "Overige",
            "textAlign": {
              "auto": true,
              "align": "left"
            },
            "representation": {
              "type": "text",
              "urlLabel": ""
            }
          },
          "qNullSuppression": false,
          "qIncludeElemValue": false,
          "qOtherTotalSpec": {
            "qOtherMode": "OTHER_OFF",
            "qOtherCounted": {
              "qv": "10"
            },
            "qOtherLimit": {
              "qv": "0"
            },
            "qOtherLimitMode": "OTHER_GE_LIMIT",
            "qSuppressOther": false,
            "qForceBadValueKeeping": true,
            "qApplyEvenWhenPossiblyWrongResult": true,
            "qGlobalOtherGrouping": false,
            "qOtherCollapseInnerDimensions": false,
            "qOtherSortMode": "OTHER_SORT_DESCENDING",
            "qTotalMode": "TOTAL_OFF",
            "qReferencedExpression": {
              "qv": ""
            }
          },
          "qShowTotal": false,
          "qShowAll": false,
          "qOtherLabel": {
            "qv": "Overige"
          },
          "qTotalLabel": {
            "qv": ""
          },
          "qCalcCond": {
            "qv": ""
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
        plangroup: {
          "qLibraryId": "",
          "qDef": {
            "qGrouping": "N",
            "qFieldDefs": [
              "[$(vD_Order.PlanGroup)]"
            ],
            "qFieldLabels": [
              ""
            ],
            "qSortCriterias": [
              {
                "qSortByState": 0,
                "qSortByFrequency": 0,
                "qSortByNumeric": 1,
                "qSortByAscii": 1,
                "qSortByLoadOrder": 1,
                "qSortByExpression": 0,
                "qExpression": {
                  "qv": ""
                },
                "qSortByGreyness": 0
              }
            ],
            "qNumberPresentations": [],
            "qReverseSort": false,
            "qActiveField": 0,
            "qLabelExpression": "='$(vD_Order.PlanGroup)'",
            "autoSort": true,
            "othersLabel": "Overige",
            "textAlign": {
              "auto": true,
              "align": "left"
            },
            "representation": {
              "type": "text",
              "urlLabel": ""
            }
          },
          "qNullSuppression": false,
          "qIncludeElemValue": false,
          "qOtherTotalSpec": {
            "qOtherMode": "OTHER_OFF",
            "qOtherCounted": {
              "qv": "10"
            },
            "qOtherLimit": {
              "qv": "0"
            },
            "qOtherLimitMode": "OTHER_GE_LIMIT",
            "qSuppressOther": false,
            "qForceBadValueKeeping": true,
            "qApplyEvenWhenPossiblyWrongResult": true,
            "qGlobalOtherGrouping": false,
            "qOtherCollapseInnerDimensions": false,
            "qOtherSortMode": "OTHER_SORT_DESCENDING",
            "qTotalMode": "TOTAL_OFF",
            "qReferencedExpression": {
              "qv": ""
            }
          },
          "qShowTotal": false,
          "qShowAll": false,
          "qOtherLabel": {
            "qv": "Overige"
          },
          "qTotalLabel": {
            "qv": ""
          },
          "qCalcCond": {
            "qv": ""
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
        plandivision: {
          "qLibraryId": "",
          "qDef": {
            "qGrouping": "N",
            "qFieldDefs": [
              "[$(vD_Order.PlanDivision)]"
            ],
            "qFieldLabels": [
              ""
            ],
            "qSortCriterias": [
              {
                "qSortByState": 0,
                "qSortByFrequency": 0,
                "qSortByNumeric": 1,
                "qSortByAscii": 1,
                "qSortByLoadOrder": 1,
                "qSortByExpression": 0,
                "qExpression": {
                  "qv": ""
                },
                "qSortByGreyness": 0
              }
            ],
            "qNumberPresentations": [],
            "qReverseSort": false,
            "qActiveField": 0,
            "qLabelExpression": "='$(vD_Order.PlanDivision)'",
            "autoSort": true,
            "othersLabel": "Overige",
            "textAlign": {
              "auto": true,
              "align": "left"
            },
            "representation": {
              "type": "text",
              "urlLabel": ""
            }
          },
          "qNullSuppression": false,
          "qIncludeElemValue": false,
          "qOtherTotalSpec": {
            "qOtherMode": "OTHER_OFF",
            "qOtherCounted": {
              "qv": "10"
            },
            "qOtherLimit": {
              "qv": "0"
            },
            "qOtherLimitMode": "OTHER_GE_LIMIT",
            "qSuppressOther": false,
            "qForceBadValueKeeping": true,
            "qApplyEvenWhenPossiblyWrongResult": true,
            "qGlobalOtherGrouping": false,
            "qOtherCollapseInnerDimensions": false,
            "qOtherSortMode": "OTHER_SORT_DESCENDING",
            "qTotalMode": "TOTAL_OFF",
            "qReferencedExpression": {
              "qv": ""
            }
          },
          "qShowTotal": false,
          "qShowAll": false,
          "qOtherLabel": {
            "qv": "Overige"
          },
          "qTotalLabel": {
            "qv": ""
          },
          "qCalcCond": {
            "qv": ""
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
        planregio: {
          "qLibraryId": "",
          "qDef": {
            "qGrouping": "N",
            "qFieldDefs": [
              "[$(vD_Order.PlanRegio)]"
            ],
            "qFieldLabels": [
              ""
            ],
            "qSortCriterias": [
              {
                "qSortByState": 0,
                "qSortByFrequency": 0,
                "qSortByNumeric": 1,
                "qSortByAscii": 1,
                "qSortByLoadOrder": 1,
                "qSortByExpression": 0,
                "qExpression": {
                  "qv": ""
                },
                "qSortByGreyness": 0
              }
            ],
            "qNumberPresentations": [],
            "qReverseSort": false,
            "qActiveField": 0,
            "qLabelExpression": "='$(vD_Order.PlanRegio)'",
            "autoSort": true,
            "othersLabel": "Overige",
            "textAlign": {
              "auto": true,
              "align": "left"
            },
            "representation": {
              "type": "text",
              "urlLabel": ""
            }
          },
          "qNullSuppression": false,
          "qIncludeElemValue": false,
          "qOtherTotalSpec": {
            "qOtherMode": "OTHER_OFF",
            "qOtherCounted": {
              "qv": "10"
            },
            "qOtherLimit": {
              "qv": "0"
            },
            "qOtherLimitMode": "OTHER_GE_LIMIT",
            "qSuppressOther": false,
            "qForceBadValueKeeping": true,
            "qApplyEvenWhenPossiblyWrongResult": true,
            "qGlobalOtherGrouping": false,
            "qOtherCollapseInnerDimensions": false,
            "qOtherSortMode": "OTHER_SORT_DESCENDING",
            "qTotalMode": "TOTAL_OFF",
            "qReferencedExpression": {
              "qv": ""
            }
          },
          "qShowTotal": false,
          "qShowAll": false,
          "qOtherLabel": {
            "qv": "Overige"
          },
          "qTotalLabel": {
            "qv": ""
          },
          "qCalcCond": {
            "qv": ""
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
        status: {
          "qLibraryId": "",
          "qDef": {
            "qGrouping": "N",
            "qFieldDefs": [
              "[$(vD_Order.Status)]"
            ],
            "qFieldLabels": [
              ""
            ],
            "qSortCriterias": [
              {
                "qSortByState": 0,
                "qSortByFrequency": 0,
                "qSortByNumeric": 1,
                "qSortByAscii": 1,
                "qSortByLoadOrder": 1,
                "qSortByExpression": 0,
                "qExpression": {
                  "qv": ""
                },
                "qSortByGreyness": 0
              }
            ],
            "qNumberPresentations": [],
            "qReverseSort": false,
            "qActiveField": 0,
            "qLabelExpression": "='$(vD_Order.Status)'",
            "autoSort": true,
            "othersLabel": "Overige",
            "textAlign": {
              "auto": true,
              "align": "left"
            },
            "representation": {
              "type": "text",
              "urlLabel": ""
            }
          },
          "qNullSuppression": false,
          "qIncludeElemValue": false,
          "qOtherTotalSpec": {
            "qOtherMode": "OTHER_OFF",
            "qOtherCounted": {
              "qv": "10"
            },
            "qOtherLimit": {
              "qv": "0"
            },
            "qOtherLimitMode": "OTHER_GE_LIMIT",
            "qSuppressOther": false,
            "qForceBadValueKeeping": true,
            "qApplyEvenWhenPossiblyWrongResult": true,
            "qGlobalOtherGrouping": false,
            "qOtherCollapseInnerDimensions": false,
            "qOtherSortMode": "OTHER_SORT_DESCENDING",
            "qTotalMode": "TOTAL_OFF",
            "qReferencedExpression": {
              "qv": ""
            }
          },
          "qShowTotal": false,
          "qShowAll": false,
          "qOtherLabel": {
            "qv": "Overige"
          },
          "qTotalLabel": {
            "qv": ""
          },
          "qCalcCond": {
            "qv": ""
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
        service: {
          "qLibraryId": "",
          "qDef": {
            "qGrouping": "N",
            "qFieldDefs": [
              "[$(vD_Order.Service)]"
            ],
            "qFieldLabels": [
              ""
            ],
            "qSortCriterias": [
              {
                "qSortByState": 0,
                "qSortByFrequency": 0,
                "qSortByNumeric": 1,
                "qSortByAscii": 1,
                "qSortByLoadOrder": 1,
                "qSortByExpression": 0,
                "qExpression": {
                  "qv": ""
                },
                "qSortByGreyness": 0
              }
            ],
            "qNumberPresentations": [],
            "qReverseSort": false,
            "qActiveField": 0,
            "qLabelExpression": "='$(vD_Order.Service)'",
            "autoSort": true,
            "othersLabel": "Overige",
            "textAlign": {
              "auto": true,
              "align": "left"
            },
            "representation": {
              "type": "text",
              "urlLabel": ""
            }
          },
          "qNullSuppression": false,
          "qIncludeElemValue": false,
          "qOtherTotalSpec": {
            "qOtherMode": "OTHER_OFF",
            "qOtherCounted": {
              "qv": "10"
            },
            "qOtherLimit": {
              "qv": "0"
            },
            "qOtherLimitMode": "OTHER_GE_LIMIT",
            "qSuppressOther": false,
            "qForceBadValueKeeping": true,
            "qApplyEvenWhenPossiblyWrongResult": true,
            "qGlobalOtherGrouping": false,
            "qOtherCollapseInnerDimensions": false,
            "qOtherSortMode": "OTHER_SORT_DESCENDING",
            "qTotalMode": "TOTAL_OFF",
            "qReferencedExpression": {
              "qv": ""
            }
          },
          "qShowTotal": false,
          "qShowAll": false,
          "qOtherLabel": {
            "qv": "Overige"
          },
          "qTotalLabel": {
            "qv": ""
          },
          "qCalcCond": {
            "qv": ""
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
        kind: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Order.Kind)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.Kind)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        kinddescription: {
          "qLibraryId": "",
          "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                  "[$(vD_Order.KindDescription)]"
              ],
              "qFieldLabels": [
                  ""
              ],
              "qSortCriterias": [
                  {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                          "qv": ""
                      },
                      "qSortByGreyness": 0
                  }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Order.KindDescription)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                  "auto": true,
                  "align": "left"
              },
              "representation": {
                  "type": "text",
                  "urlLabel": ""
              }
          },
          "qNullSuppression": false,
          "qIncludeElemValue": false,
          "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                  "qv": "10"
              },
              "qOtherLimit": {
                  "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                  "qv": ""
              }
          },
          "qShowTotal": false,
          "qShowAll": false,
          "qOtherLabel": {
              "qv": "Overige"
          },
          "qTotalLabel": {
              "qv": ""
          },
          "qCalcCond": {
              "qv": ""
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCondition": {
              "qCond": {
                  "qv": ""
              },
              "qMsg": {
                  "qv": ""
              }
          }
      },
    },
    loadinglocation: {
        id: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Order.LoadingLocation.ID)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.LoadingLocation.ID)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        name: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Order.LoadingLocation.Name)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.LoadingLocation.Name)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        adress: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Order.LoadingLocation.Adress)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.LoadingLocation.Adress)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        searchcode: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Order.LoadingLocation.SearchCode)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.LoadingLocation.SearchCode)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        zipcode: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Order.LoadingLocation.ZIPcode)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.LoadingLocation.ZIPcode)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        city: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Order.LoadingLocation.City)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.LoadingLocation.City)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        district: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Order.LoadingLocation.District)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.LoadingLocation.District)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        province: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Order.LoadingLocation.Province)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.LoadingLocation.Province)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        region: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Order.LoadingLocation.Region)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.LoadingLocation.Region)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        financialregion: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Order.LoadingLocation.FinancialRegion)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.LoadingLocation.FinancialRegion)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        financialregiondescription: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Order.LoadingLocation.FinancialRegionDescription)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.LoadingLocation.FinancialRegionDescription)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        operationalregion: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Order.LoadingLocation.OperationalRegion)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.LoadingLocation.OperationalRegion)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        operationalregiondescription: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Order.LoadingLocation.OperationalRegionDescription)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.LoadingLocation.OperationalRegionDescription)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        country: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Order.LoadingLocation.Country)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.LoadingLocation.Country)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        land: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Order.LoadingLocation.Land)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.LoadingLocation.Land)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
    },
    unloadinglocation: {
        id: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Order.UnloadingLocation.ID)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.UnloadingLocation.ID)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        name: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Order.UnloadingLocation.Name)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.UnloadingLocation.Name)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        adress: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Order.UnloadingLocation.Adress)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.UnloadingLocation.Adress)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        searchcode: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Order.UnloadingLocation.SearchCode)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.UnloadingLocation.SearchCode)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        zipcode: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Order.UnloadingLocation.ZIPcode)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.UnloadingLocation.ZIPcode)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        city: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Order.UnloadingLocation.City)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.UnloadingLocation.City)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        district: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Order.UnloadingLocation.District)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.UnloadingLocation.District)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        province: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Order.UnloadingLocation.Province)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.UnloadingLocation.Province)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        region: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Order.UnloadingLocation.Region)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.UnloadingLocation.Region)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        financialregion: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Order.UnloadingLocation.FinancialRegion)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.UnloadingLocation.FinancialRegion)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        financialregiondescription: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Order.UnloadingLocation.FinancialRegionDescription)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.UnloadingLocation.FinancialRegionDescription)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        operationalregion: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Order.UnloadingLocation.OperationalRegion)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.UnloadingLocation.OperationalRegion)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        operationalregiondescription: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Order.UnloadingLocation.OperationalRegionDescription)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.UnloadingLocation.OperationalRegionDescription)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        country: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Order.UnloadingLocation.Country)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.UnloadingLocation.Country)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        land: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Order.UnloadingLocation.Land)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Order.UnloadingLocation.Land)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
    },
    period: {
        year: {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Year)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Year)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        quarter:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Quarter)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Quarter)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        month:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Month)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Month)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        period:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Period)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Period)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        week:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Week)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Week)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        yearweek:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_YearWeek)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_YearWeek)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        date:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Date)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Date)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        weekday:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Weekday)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Weekday)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          }
    }
};
const measures = {
    omzet: {
        totaal: {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderOmzet",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderOmzet)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderOmzet)' & ' > ' & '$(vB_Alle)'",
              "autoSort": true,
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        freight:   {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderOmzet",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderOmzet(Kind={'Freight'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderOmzet)' & ' > ' & '$(vB_Freight)'",
              "autoSort": true,
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        toll:   {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderOmzet",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderOmzet(Kind={'Toll'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderOmzet)' & ' > ' & '$(vB_Toll)'",
              "autoSort": true,
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        fuel: {
          "qLibraryId": "",
          "qDef": {
            "qLabel": "OrderOmzet",
            "qDescription": "",
            "qTags": [],
            "qGrouping": "N",
            "qDef": "$(vE_OrderOmzet(Kind={'Fuel'}))",
            "qNumFormat": {
              "qType": "U",
              "qnDec": 10,
              "qUseThou": 0,
              "qFmt": "",
              "qDec": "",
              "qThou": ""
            },
            "qRelative": false,
            "qBrutalSum": false,
            "qAggrFunc": "Expr",
            "qAccumulate": 0,
            "qReverseSort": false,
            "qActiveExpression": 0,
            "qExpressions": [],
            "qLabelExpression": "='$(vL_OrderOmzet)' & ' > ' & '$(vB_Fuel)'",
            "autoSort": true,
            "numFormatFromTemplate": true,
            "textAlign": {
              "auto": true,
              "align": "left"
            }
          },
          "qSortBy": {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": -1,
            "qSortByAscii": 0,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCond": {
            "qv": ""
          },
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
        maut:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderOmzet",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderOmzet(Kind={'Maut'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderOmzet)' & ' > ' & '$(vB_Maut)'",
              "autoSort": true,
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        extra:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderOmzet",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderOmzet(Kind={'Extra Kosten'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderOmzet)' & ' > ' & 'Extra Kosten'",
              "autoSort": true,
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        other:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderOmzet",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderOmzet(Kind={'Other'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderOmzet)' & ' > ' & '$(vB_Other)'",
              "autoSort": true,
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        notransport:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderOmzetNoTransport)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderOmzetNoTransport)'",
              "autoSort": true,
              "cId": "qSZzhj",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          }
    },
    kosten: {
        totaal: {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderKosten)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderKosten)' & ' > ' & '$(vB_Alle)'",
              "autoSort": true,
              "cId": "eUNc",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        charter:   {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderKosten(Kind={'Charter'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderKosten)' & ' > ' & '$(vB_Charter)'",
              "autoSort": true,
              "cId": "epyyN",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        rail:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderKosten(Kind={'RailFerry'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderKosten)' & ' > ' & '$(vB_RailFerry)'",
              "autoSort": true,
              "cId": "uwdtmaD",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        toll:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderKosten(Kind={'Toll'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderKosten)' & ' > ' & '$(vB_Toll)'",
              "autoSort": true,
              "cId": "fKyACW",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        cleaning:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderKosten(Kind={'Cleaning'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderKosten)' & ' > ' & '$(vB_Cleaning)'",
              "autoSort": true,
              "cId": "sjpWGCN",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        codriver:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderKosten(Kind={'CoDriver'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderKosten)' & ' > ' & '$(vB_CoDriver)'",
              "autoSort": true,
              "cId": "YjeF",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        collect:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderKosten(Kind={'Collect'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderKosten)' & ' > ' & '$(vB_Collect)'",
              "autoSort": true,
              "cId": "VPUAJN",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        warehouse:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderKosten(Kind={'Warehouse'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderKosten)' & ' > ' & '$(vB_Warehouse)'",
              "autoSort": true,
              "cId": "aJk",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        km:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderKosten(Kind={'KM'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderKosten)' & ' > ' & 'KM'",
              "autoSort": true,
              "cId": "aJk1",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        other:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderKosten(Kind={'Other'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderKosten)' & ' > ' & '$(vB_Other)'",
              "autoSort": true,
              "cId": "ucMQue",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        jib:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderKosten(Kind={'JIB'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderKosten)' & ' > ' & 'JIB'",
              "autoSort": true,
              "cId": "ucMQue",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        boat:  {
              "qLibraryId": "",
              "qDef": {
                "qLabel": "",
                "qDescription": "",
                "qTags": [],
                "qGrouping": "N",
                "qDef": "$(vE_OrderKosten(Kind={'Ferry'}))",
                "qNumFormat": {
                  "qType": "U",
                  "qnDec": 10,
                  "qUseThou": 0,
                  "qFmt": "",
                  "qDec": "",
                  "qThou": ""
                },
                "qRelative": false,
                "qBrutalSum": false,
                "qAggrFunc": "Expr",
                "qAccumulate": 0,
                "qReverseSort": false,
                "qActiveExpression": 0,
                "qExpressions": [],
                "qLabelExpression": "='$(vL_OrderKosten)' & ' > ' & 'Boot'",
                "autoSort": true,
                "cId": "ucMQue",
                "numFormatFromTemplate": true,
                "textAlign": {
                  "auto": true,
                  "align": "left"
                }
              },
              "qSortBy": {
                "qSortByState": 0,
                "qSortByFrequency": 0,
                "qSortByNumeric": -1,
                "qSortByAscii": 0,
                "qSortByLoadOrder": 1,
                "qSortByExpression": 0,
                "qExpression": {
                  "qv": ""
                },
                "qSortByGreyness": 0
              },
              "qAttributeExpressions": [],
              "qAttributeDimensions": [],
              "qCalcCond": {
                "qv": ""
              },
              "qCalcCondition": {
                "qCond": {
                  "qv": ""
                },
                "qMsg": {
                  "qv": ""
                }
              }
            },
        fuelcorrection:  {
                  "qLibraryId": "",
                  "qDef": {
                    "qLabel": "",
                    "qDescription": "",
                    "qTags": [],
                    "qGrouping": "N",
                    "qDef": "$(vE_OrderKosten(Kind={'FuelCorrection'}))",
                    "qNumFormat": {
                      "qType": "U",
                      "qnDec": 10,
                      "qUseThou": 0,
                      "qFmt": "",
                      "qDec": "",
                      "qThou": ""
                    },
                    "qRelative": false,
                    "qBrutalSum": false,
                    "qAggrFunc": "Expr",
                    "qAccumulate": 0,
                    "qReverseSort": false,
                    "qActiveExpression": 0,
                    "qExpressions": [],
                    "qLabelExpression": "='$(vL_OrderKosten)' & ' > ' & 'Correctie brandstof'",
                    "autoSort": true,
                    "cId": "ucMQue",
                    "numFormatFromTemplate": true,
                    "textAlign": {
                      "auto": true,
                      "align": "left"
                    }
                  },
                  "qSortBy": {
                    "qSortByState": 0,
                    "qSortByFrequency": 0,
                    "qSortByNumeric": -1,
                    "qSortByAscii": 0,
                    "qSortByLoadOrder": 1,
                    "qSortByExpression": 0,
                    "qExpression": {
                      "qv": ""
                    },
                    "qSortByGreyness": 0
                  },
                  "qAttributeExpressions": [],
                  "qAttributeDimensions": [],
                  "qCalcCond": {
                    "qv": ""
                  },
                  "qCalcCondition": {
                    "qCond": {
                      "qv": ""
                    },
                    "qMsg": {
                      "qv": ""
                    }
                  }
                }
            

    },
    aantal: {
        dossiers: {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderAantalOrders",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderAantalDossiers)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderAantalDossiers)'",
              "autoSort": true,
              "cId": "UUmPzu",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        orders:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderAantalOrders",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderAantalOrders)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderAantalOrders)'",
              "autoSort": true,
              "cId": "UUmPzu",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        ritten:   {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderAantalRitten",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderAantalRitten)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderAantalRitten)'",
              "autoSort": true,
              "cId": "PjAQRY",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        afwijkingen:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderAantalAfwijkingen",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderAantalAfwijkingen)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderAantalAfwijkingen)'",
              "autoSort": true,
              "cId": "PjAQRY",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          }
    },
    kpi: {
        goedkeur: {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderPercGoedkeur",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_KPI_OrderGoedkeur)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_KPI_OrderGoedkeur)'",
              "autoSort": true,
              "cId": "Rnnjp",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        normmarginpercent:   {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderMarge",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_KPI_OrderNormMarginPercent)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_KPI_OrderNormMarginPercent)'",
              "autoSort": true,
              "cId": "gkeLVN",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [
              {
                "qExpression": "if($(vE_KPI_OrderNormMarginPercent) < 0 ,rgb(255,0,0), rgb(0,127,0))",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellForegroundColor"
              }
            ],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        normmarginomzet:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_KPI_OrderNormMarginOverOmzet)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_KPI_OrderNormMarginOverOmzet)'",
              "autoSort": true,
              "cId": "axZsAXz",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [
              {
                "qExpression": "if($(vE_KPI_OrderNormMarginPercent) < 0 ,rgb(255,0,0), rgb(0,127,0))",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellForegroundColor"
              }
            ],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          }
    },
    own: {
        omzetperuur: {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderOmzetPerUur",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_KPI_OrderOmzetPerUur)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_KPI_OrderOmzetPerUur)'",
              "autoSort": true,
              "cId": "MJZcvk",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [
              {
                "qExpression": "if($(vE_KPI_OrderOmzetPerUur) < 0.85 * $(vE_OrderNormTurnoverHour) ,rgb(176,23,31), if($(vE_KPI_OrderOmzetPerUur) <  $(vE_OrderNormTurnoverHour) ,rgb(244,164,96), rgb(46,139,87)))\r\n\n\n\n",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellForegroundColor"
              }
            ],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        omzetperuurinclfuel: {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_KPI_OrderOmzetPerUurIncFuel)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_KPI_OrderOmzetPerUurIncFuel)'",
              "autoSort": true,
              "cId": "eUNc",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        omzetperuurexfuel: {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderOmzetPerUur",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_KPI_OrderOmzetPerUurExFuel)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_KPI_OrderOmzetPerUurExFuel)'",
              "autoSort": true,
              "cId": "MJZcvk",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [
              {
                "qExpression": "if($(vE_KPI_OrderOmzetPerUurExFuel) < 0.85 * $(vE_OrderNormTurnoverHour) ,rgb(176,23,31), if($(vE_KPI_OrderOmzetPerUurExFuel) <  $(vE_OrderNormTurnoverHour) ,rgb(244,164,96), rgb(46,139,87)))\r\n\n\n\n",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellForegroundColor"
              }
            ],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        tripomzetperuur:   {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "TripOmzetPerUur",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_KPI_Order_TripOmzetPerUur)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "= '$(vL_KPI_Order_TripOmzetPerUur)'",
              "autoSort": true,
              "cId": "NyMgCae",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [
              {
                "qExpression": "if($(vE_KPI_Order_TripOmzetPerUur) < 0.85 * $(vE_OrderNormTurnoverHour) ,rgb(176,23,31), if($(vE_KPI_Order_TripOmzetPerUur) <  $(vE_OrderNormTurnoverHour) ,rgb(244,164,96), rgb(46,139,87)))",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellForegroundColor"
              }
            ],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        tripomzetperuurinclfuel:   {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "TripOmzetPerUur",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_KPI_Order_TripOmzetPerUurIncFuel)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "= '$(vL_KPI_Order_TripOmzetPerUurIncFuel)'",
              "autoSort": true,
              "cId": "NyMgCae",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [
              {
                "qExpression": "if($(vE_KPI_Order_TripOmzetPerUurIncFuel) < 0.85 * $(vE_OrderNormTurnoverHour) ,rgb(176,23,31), if($(vE_KPI_Order_TripOmzetPerUurIncFuel) <  $(vE_OrderNormTurnoverHour) ,rgb(244,164,96), rgb(46,139,87)))",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellForegroundColor"
              }
            ],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },          
        tripomzetperuurexfuel:   {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "TripOmzetPerUur",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_KPI_Order_TripOmzetPerUurExFuel)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "= '$(vL_KPI_Order_TripOmzetPerUurExFuel)'",
              "autoSort": true,
              "cId": "NyMgCae",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [
              {
                "qExpression": "if($(vE_KPI_Order_TripOmzetPerUurExFuel) < 0.85 * $(vE_OrderNormTurnoverHour) ,rgb(176,23,31), if($(vE_KPI_Order_TripOmzetPerUurExFuel) <  $(vE_OrderNormTurnoverHour) ,rgb(244,164,96), rgb(46,139,87)))",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellForegroundColor"
              }
            ],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        normomzetperuur:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "=$(vE_OrderNormTurnoverHour)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderNormTurnoverHour)'",
              "autoSort": true,
              "cId": "qcGe",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        omzetperuurvolgende:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_KPI_OrderOmzetPerUurCombinatieVolgendeOrder)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "= '$(vL_KPI_OrderOmzetPerUurCombinatieVolgendeOrder)'",
              "autoSort": true,
              "cId": "DKbBEk",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [
              {
                "qExpression": "if($(vE_KPI_OrderOmzetPerUurCombinatieVolgendeOrder) < 0.85 * $(vE_OrderNormTurnoverHour) ,rgb(255,255,255), if($(vE_KPI_OrderOmzetPerUurCombinatieVolgendeOrder) <  $(vE_OrderNormTurnoverHour) ,RGB(0,0,0), RGB(0,0,0)))",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellForegroundColor"
              },
              {
                "qExpression": "if($(vE_KPI_OrderOmzetPerUurCombinatieVolgendeOrder) < 0.85 * $(vE_OrderNormTurnoverHour) ,rgb(255,0,0), if($(vE_KPI_OrderOmzetPerUurCombinatieVolgendeOrder) <  $(vE_OrderNormTurnoverHour) ,RGB(255,242,0), RGB(34,177,76))) //RGB(255,127,39)",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellBackgroundColor"
              }
            ],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        omzetperuurvolgenderit:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_KPI_OrderOmzetPerUurCombinatieVolgendeRit)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "= '$(vL_KPI_OrderOmzetPerUurCombinatieVolgendeRit)'",
              "autoSort": true,
              "cId": "DKbBEk",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [
              {
                "qExpression": "if($(vE_KPI_OrderOmzetPerUurCombinatieVolgendeRit) < 0.85 * $(vE_OrderNormTurnoverHour) ,rgb(176,23,31), if($(vE_KPI_OrderOmzetPerUurCombinatieVolgendeRit) <  $(vE_OrderNormTurnoverHour) ,rgb(244,164,96), rgb(46,139,87)))",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellForegroundColor"
              },
            ],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        bezetting:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "=$(vE_KPI_OrderBezetting)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_KPI_OrderBezetting)'",
              "autoSort": true,
              "cId": "TCNkRn",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [
              {
                "qExpression": "if($(vE_KPI_OrderBezetting) < 0 ,rgb(255,0,0), RGB(34,177,76))",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellForegroundColor"
              }
            ],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          }
    },
    outsourced: {
      percentage: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderTripOutsourced)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderTripOutsourced)'",
          "autoSort": true,
          "cId": "fPNrVLL",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      marge: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderCharterMarge)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderCharterMarge)'",
          "autoSort": true,
          "cId": "jfPSwtw",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [
          {
            "qExpression": "if($(vE_KPI_OrderCharterMarge) < 0, rgb(176,23,31), if($(vE_KPI_OrderCharterMarge) < $(vE_OrderNormCharter) ,rgb(244,164,96), rgb(46,139,87)))",
            "qLibraryId": "",
            "qAttribute": true,
            "id": "cellForegroundColor"
          }
        ],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      norm: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderNormCharter)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderNormCharter)",
          "autoSort": true,
          "cId": "VehHD",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      }
    },
    potentie: {
      totaal: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderPotency)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderPotency)' & ' > ' & '$(vB_Alle)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      operationeel: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderPotency(Kind={'Operational'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderPotency)' & ' > ' & '$(vB_Operationeel)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      commercieel: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderPotency(Kind={'Commercial'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderPotency)' & ' > ' & '$(vB_Commercieel)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      }
    },
    km: {
      omzetperkm: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "=$(vE_KPI_OrderOmzetPerKM)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderOmzetPerKM)'",
          "autoSort": true,
          "cId": "PZLtX",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [
          {
            "qExpression": "if($(vE_KPI_OrderOmzetPerKM) < $(vE_OrderNormTurnoverKM) ,rgb(255,0,0), rgb(0,127,0))",
            "qLibraryId": "",
            "qAttribute": true,
            "id": "cellForegroundColor"
          }
        ],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      omzetperkmfull: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderOmzetPerKMFull)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderOmzetPerKMFull))'",
          "autoSort": true,
          "cId": "RtDbpmg",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [
          {
            "qExpression": "if($(vE_KPI_OrderOmzetPerKMFull) < $(vE_OrderNormTurnoverKM) ,rgb(255,0,0), rgb(0,127,0))",
            "qLibraryId": "",
            "qAttribute": true,
            "id": "cellForegroundColor"
          }
        ],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      belading: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "=$(vE_KPI_OrderBelading)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderBelading)'",
          "autoSort": true,
          "cId": "XsjRvj",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      km: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderKM)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='KM >' &  '$(vB_Alle)'",
          "autoSort": true,
          "cId": "eyFxN",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      kmfull: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderKM(Kind={'Full'} ))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='KM >' &  '$(vB_Full)'",
          "autoSort": true,
          "cId": "DDXhmkT",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      kmempty: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderKM(Kind={'Empty'} ))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='KM >' &  '$(vB_Empty)'",
          "autoSort": true,
          "cId": "jcpszEx",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      normomzetperkm: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderNormTurnoverKM)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderNormTurnoverKM)'",
          "autoSort": true,
          "cId": "WjmPM",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      normomzetperkmfull: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderNormTurnoverKMFull)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderNormTurnoverKMFull)'",
          "autoSort": true,
          "cId": "vQmHLwc",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      }
    },
    uren: {
      totaal: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderUren)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUren)'  & ' > ' & '$(vB_Alle)' & ' (' & '$(vB_Sum)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      loading: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderUren(Kind={'Loading'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUren)'  & ' > ' & '$(vB_Loading)' & ' (' & '$(vB_Sum)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      unloading: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderUren(Kind={'Unloading'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUren)'  & ' > ' & '$(vB_Unloading)' & ' (' & '$(vB_Sum)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      driving: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderUren(Kind={'Driving'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUren)'  & ' > ' & '$(vB_Driving)' & ' (' & '$(vB_Sum)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      cleaning: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderUren(Kind={'Cleaning'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUren)'  & ' > ' & '$(vB_Cleaning)' & ' (' & '$(vB_Sum)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      other: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderUren(Kind={'Other'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUren)'  & ' > ' & '$(vB_Other)' & ' (' & '$(vB_Sum)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_totaal: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderUrenAvg)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUrenAvg)'  & ' > ' & '$(vB_Alle)' & ' (' & '$(vB_Avg)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_loading: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderUrenAvg(Kind={'Loading'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUrenAvg)'  & ' > ' & '$(vB_Loading)' & ' (' & '$(vB_Avg)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_unloading: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderUrenAvg(Kind={'Unloading'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUrenAvg)'  & ' > ' & '$(vB_Unloading)' & ' (' & '$(vB_Avg)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_driving: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderUrenAvg(Kind={'Driving'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUrenAvg)'  & ' > ' & '$(vB_Driving)' & ' (' & '$(vB_Avg)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_cleaning: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderUrenAvg(Kind={'Cleaning'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUrenAvg)'  & ' > ' & '$(vB_Cleaning)' & ' (' & '$(vB_Avg)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_other: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderUrenAvg(Kind={'Other'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUrenAvg)'  & ' > ' & '$(vB_Other)' & ' (' & '$(vB_Avg)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      }
    },
    previousyear: {
      omzet: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderOmzet($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))\r\n\n",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderOmzet) ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      orders: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderAantalOrders($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderAantalOrders) ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      ritten: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderAantalRitten($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderAantalRitten) ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      normmarge: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderNormMarginPercent($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderNormMarginPercent) ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      normmargeomzet: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderNormMarginOverOmzet($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderNormMarginOverOmzet) ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      goedkeur: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderGoedkeur($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderGoedkeur) ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      omzetperuur: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderOmzetPerUur($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderOmzetPerUur) ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      uitbesteed: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderTripOutsourced($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderTripOutsourced) ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      volgendeorder: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderOmzetPerUurCombinatieVolgendeOrder($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderOmzetPerUurCombinatieVolgendeOrder) ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      chargermarge: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderCharterMarge($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderCharterMarge) ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      lm: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderLM($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderLM)  ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_lm: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderLMAvg($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderLMAvg) ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      kg: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderKG($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderKG) ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_kg: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderKGAvg($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderKGAvg) ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
    },
    persize: {
      lm: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderLM)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderLM)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_lm: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderLMAvg)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderLMAvg)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      m3: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderM3)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderM3)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_m3: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderM3Avg)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderM3Avg)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      kg: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderKG)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderKG)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_kg: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderKGAvg)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderKGAvg)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      pp: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderPP)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderPP)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_pp: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderPPAvg)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderPPAvg)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      colli: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderColli)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderColli)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_colli: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderColliAvg)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderColliAvg)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      shipmentsize: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderShipmentSize)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderShipmentSize)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      shipmentsizeavg: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderShipmentSizeAvg)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderShipmentSizeAvg)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      omzet_shipmentsize: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderOmzetPerZendingGrootte)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderOmzetPerZendingGrootte)'",
          "autoSort": true,
          "cId": "DtBnG",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      loading_shipmentsize: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderLaadMinutenPerZendingGrootte)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderLaadMinutenPerZendingGrootte)'",
          "autoSort": true,
          "cId": "JaYwj",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      unloading_shipmentsize: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderLosMinutenPerZendingGrootte)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderLosMinutenPerZendingGrootte)'",
          "autoSort": true,
          "cId": "gZBJWx",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      time_shipmentsize: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderMinutenPerZendingGrootte)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderMinutenPerZendingGrootte)'",
          "autoSort": true,
          "cId": "EdnRYS",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      }
    }
};

export {measures, dimensions};