import qlik from './qlik';
import {dimensions, measures} from './qlik/klant/klant';
export default [
    {
      value: 'dimensions',
      label: 'Dimensies',
      class: 'bg-primary',
      children: [
        {
          label: 'Klant',
          value: 'klant',
          children: [
            {
              label: 'Naam',
              value: 'vD_Order.DebiteurNaam',
              qlik: dimensions.debtor.name,
              new_value: "dimensions.debtor.name",
            },
            {
              label: 'Nummer',
              value: 'vD_Order.DebiteurNummer',
              qlik: dimensions.debtor.number,
              new_value: "dimensions.debtor.number",
            },
            {
                label: 'Groep',
                value: 'vD_Order.DebiteurGroep',
                qlik: dimensions.debtor.type,
                new_value: "dimensions.debtor.type"
              }
          ]
        },
        {
          label: 'Order',
          value: 'order',
          children: [
            {
              value: 'vD_Order.OrderNumberDisplay',
              label: 'Order Nummer',
              qlik: dimensions.order.ordernumberdisplay,
              new_value: "dimensions.order.ordernumberdisplay"
            }
          ]
        },
        {
          label: 'Laad Locatie',
          value: 'laadlocatie',
          children: [
            {
              value: 'vD_Order.LoadingLocation.ID',
              label: 'ID',
              qlik: dimensions.loadinglocation.id,
              new_value: "dimensions.loadinglocation.id"
            },
            {
              value: 'vD_Order.LoadingLocation.Name',
              label: 'Naam',
              qlik: dimensions.loadinglocation.name,
              new_value: "dimensions.loadinglocation.name"
            },
            {
              value: 'vD_Order.LoadingLocation.ZIPcode',
              label: 'Postcode',
              qlik: dimensions.loadinglocation.zipcode,
              new_value: "dimensions.loadinglocation.zipcode"
            },
            {
              value: 'vD_Order.LoadingLocation.City',
              label: 'Plaats',
              qlik: dimensions.loadinglocation.city,
              new_value: "dimensions.loadinglocation.city",
            },
            {
              value: 'vD_Order.LoadingLocation.Country',
              label: 'Land',
              qlik: dimensions.loadinglocation.country,
              new_value: "dimensions.loadinglocation.country",
            },
          ]
        },
        {
          label: 'Los Locatie',
          value: 'loslocatie',
          children: [
            {
              value: 'vD_Order.UnloadingLocation.ID',
              label: 'ID',
              qlik: dimensions.unloadinglocation.id,
              new_value: "dimensions.unloadinglocation.id"
            },
            {
              value: 'vD_Order.UnloadingLocation.Name',
              label: 'Naam',
              qlik: dimensions.unloadinglocation.name,
              new_value: "dimensions.unloadinglocation.name"
            },
            {
              value: 'vD_Order.UnloadingLocation.ZIPcode',
              label: 'Postcode',
              qlik: dimensions.unloadinglocation.zipcode,
              new_value: "dimensions.unloadinglocation.zipcode"
            },
            {
              value: 'vD_Order.UnloadingLocation.City',
              label: 'Plaats',
              qlik: dimensions.unloadinglocation.city,
              new_value: "dimensions.unloadinglocation.city",
            },
            {
              value: 'vD_Order.UnloadingLocation.Country',
              label: 'Land',
              qlik: dimensions.unloadinglocation.country,
              new_value: "dimensions.unloadinglocation.country",
            },
          ]
        },
        {
          label: 'Periode',
          value: 'periode',
          children: [
            {
              value: 'vD_Year',
              label: 'Jaar',
              qlik: dimensions.period.year,
              new_value: "dimensions.period.year",
            },
            {
              value: 'vD_Quarter',
              label: 'Kwartaal',
              qlik: dimensions.period.quarter,
              new_value: "dimensions.period.quarter"
            },
            {
              value: 'vD_Month',
              label: 'Maand',
              qlik: dimensions.period.month,
              new_value: "dimensions.period.month",
            },
            {
              value: 'vD_Week',
              label: 'Week',
              qlik: dimensions.period.week,
              new_value: "dimensions.period.week",
            },
            {
              value: 'vD_Date',
              label: 'Datum',
              qlik: dimensions.period.date,
              new_value: "dimensions.period.date"
            },
          ]
        }
      ]
    },
    {
      label: 'Meetwaarden',
      value: 'measures',
      class: 'bg-danger',
      children: [
        {
          value: 'omzet',
          label: 'Omzet',
          children: [
            {
              value: 'vE_OrderOmzet',
              label: 'Totaal',
              qlik: measures.omzet.totaal,
              new_value: "measures.omzet.totaal"
            },
            {
                qlik: measures.omzet.freight,
                value: 'vE_OrderOmzetFreight',
                label: 'Vracht'
              },
              {
                qlik: measures.omzet.fuel,
                value: 'vE_OrderOmzetFuel',
                label: 'Brandstof'
              },
              {
                qlik: measures.omzet.other,
                value: 'vE_OrderOmzetOther',
                label: 'Overig'
              }
          ]
        },
        {
          value: 'kosten',
          label: 'Kosten',
          children: [
            {
              value: 'vE_OrderKosten',
              label: 'Totaal',
              qlik: measures.kosten.totaal,
              new_value: "measures.kosten.totaal",
              help: 'Totaal van de directe kosten.'
            },
            {
              value: 'vE_OrderKostenCharter',
              label: 'Derden',
              qlik: measures.kosten.charter,
              new_value: "measures.kosten.charter",
              help: 'Kosten van charters.'

            },
            // {
            //   value: 'vE_OrderKostenRailFerry',
            //   label: 'Trein',
            // },
            {
              value: 'vE_OrderKostenTol',
              label: 'Tol',
              qlik: measures.kosten.toll,
              new_value: "measures.kosten.toll",
              help: 'Tolkosten zoals deze uit Visual Planner komen.'
            },
            // {
            //   value: 'vE_OrderKostenCleaning',
            //   label: 'Cleaning',
            // },
            // {
            //   value: 'vE_OrderKostenCoDriver',
            //   label: 'Bijrijder',
            // },
            // {
            //   value: 'vE_OrderKostenCollect',
            //   label: 'Voorhaal',
            // },
            {
              value: 'vE_OrderKostenWarehouse',
              label: 'Warehouse',
              qlik: measures.kosten.warehouse,
              new_value: "measures.kosten.warehouse",
              help: 'Voorcalculatorische kosten berekend door de MargeMonitor voor de reservering van de directe crossdock kosten per zending'
            },
            // {
            //   value: 'vE_OrderKostenOther',
            //   label: 'Other',
            // },
          ]
        },
        {
          value: 'aantal',
          label: 'Aantal',
          children: [
            {
              value: 'vE_OrderAantalOrders',
              label: 'Order',
              qlik: measures.aantal.orders,
              new_value: "measures.aantal.orders",
              help: 'Het aantal zendingen, waarbij een zending een unieke transportopdracht is van A naar B'
            },
            {
              value: 'vE_OrderAantalRitten',
              label: 'Ritten',
              qlik: measures.aantal.ritten,
              new_value: "measures.aantal.ritten",
              help: 'Het aantal ritten behorend bij de geselecteerde zendingen. Hier zien we altijd het totaal. '
            },
          ]
        },
        {
          value: 'kpi',
          label: 'KPI',
          children: [
            {
              value: 'vE_KPI_OrderGoedkeur',
              label: 'OK',
              qlik: measures.kpi.goedkeur,
              new_value: "measures.kpi.goedkeur",
              help: 'Het percentage zendingen dat goedgekeurd is. Wanneer er iets misgaat met een zending wordt deze niet meegenomen in de kengetallen.'
            },
  
            {
              value: 'vE_KPI_OrderNormMarginPercent',
              label: 'Norm Marge %',
              qlik: measures.kpi.normmarginpercent,
              new_value: "measures.kpi.normmarginpercent",
              help: 'De Norm Marge combineert de charter marge en de omzet/uur om deze samen te toetsen tegen de gestelde normen. Voor het eigen werk wordt gekeken hoeveel de omzet / uur afwijkt van de norm voor de omzet/uur. Dit wordt gecombineerd met de afwijking van de chartermarge ten opzichte van de norm voor de chartermarge. Dit wordt enkel uitgerekend voor goedgekeurde orders.'
            },
            {
              value: 'vE_KPI_OrderNormMarginOverOmzet',
              label: 'Norm Marge',
              qlik: measures.kpi.normmarginomzet,
              new_value: "measures.kpi.normmarginomzet",
              help: 'De Norm Marge interpoleert het berekende percentage over de gehele omzet.'
            },
  
  
          ]
        },
        {
          value: 'eigenwagen',
          label: 'Eigen Wagen',
          children: [
            {
              value: 'vE_KPI_OrderOmzetPerUur',
              label: 'Omzet / Uur',
              qlik: measures.own.omzetperuur,
              new_value: "measures.own.omzetperuur",
              help: 'De omzet / uur is de omzet van het eigen werk minus de directe-kosten (Tol, X-dock) van het eigen werk afgezet tegen het aantal ingezette uren. Het gaat hier altijd enkel om goedgekeurde orders. '
            },
            {
              value: "measures.own.omzetperuurexfuel",
              qlik: measures.own.omzetperuurexfuel,
              label: 'Omzet / Uur zonder DOT', 
              help: 'De omzet / uur zonder DOT is de omzet van het eigen werk minus de DOT en minus de directe-kosten (Tol, X-dock) van het eigen werk afgezet tegen het aantal ingezette uren. Het gaat hier altijd enkel om goedgekeurde orders. '
            },
            {
              value: 'vE_KPI_Order_TripOmzetPerUur',
              label: 'Omzet / Uur - rit',
              qlik: measures.own.tripomzetperuur,
              new_value: "measures.own.tripomzetperuur",
              help: 'De omzet / uur van de gehele rit(ten) waar deze order(s) ingezeten heeft/hebben. Het gaat hier altijd enkel om goedgekeurde ritten. '
            },
            {
              value: 'vE_KPI_OrderOmzetPerUurCombinatieVolgendeOrder',
              label: 'Omzet / Uur - rondrit',
              qlik: measures.own.omzetperuurvolgende,
              help: 'De omzet / uur rondrit is de combinatie van de geselecteerde order + (volgende order of voorgaande order). Aan de rechterzijde kun je in de opties aangeven of je de volgende order, de voorgaande order of zowel de voorgaande in het kengetal omzet/uur rondrit gebruiken. Standaard staat deze instelling op de volgende order. Met dit kengetal kun je een rondrit analyseren zodat de goede heen orders en de slechtere retour orders in combinatie analyseert.',
              new_value:'measures.own.omzetperuurvolgende'
            },
            {
              value: 'vE_KPI_OrderBezetting',
              label: 'Bezetting',
              qlik: measures.own.bezetting,
              new_value:'measures.own.bezetting'
            },
            {
              value: 'vE_OrderNormTurnoverHour',
              label: 'Norm Omzet / Uur',
              qlik: measures.own.normomzetperuur,
              help: 'De geconfigureerde norm op orderniveau  voor het eigen werk. De omzet per uur wordt getoetst aan deze norm. ',
              new_value:'measures.own.normomzetperuur'
            },
          ]
        },
        {
          value: 'uitbesteed',
          label: 'Uitbesteed',
          children: [
            {
              value: 'vE_KPI_OrderTripOutsourced',
              label: '% Uitbesteed',
              qlik: measures.outsourced.percentage,
              new_value:'measures.outsourced.percentage',
              help: 'Het percentage dat is uitbesteed. Indien een zending in het voorhaaltraject door een eigen wagen uitgevoerd wordt en het wegbrengen wordt uitbesteed is deze zending voor 50% uitbesteed.'
            },
            {
              value: 'vE_KPI_OrderCharterMarge',
              label: 'Derden Marge',
              qlik: measures.outsourced.marge,
              help: 'De marge op het uitbesteede werk. Dit is de omzet minus de kosten. Hierbij wordt enkel de omzet en de kosten meegenomen indien de order goedgekeurd is. ',
              new_value:'measures.outsourced.marge',
            },
            {
              value: 'vE_OrderNormCharter',
              label: 'Norm',
              qlik: measures.outsourced.norm,
              help: 'De geconfigureerde norm op orderniveau voor het uitbestede werk. De Derden Marge wordt getoetst aan deze norm. ',
              new_value:'measures.outsourced.norm',
            },
          ]
        },
        {
          value: 'potency',
          label: 'Potentie',
          children: [
            {
              value: 'vE_OrderPotency',
              label: 'Totaal',
              qlik: measures.potentie.totaal,
              help:'Potentie geeft aan hoeveel hoger de omzet zou moeten zijn om volledig volgens de gestelde norm omzet/uur te presteren voor eigen werk. De potentie wordt enkel uitgerekend voor orders die onder de norm presteren. Orders die boven de norm presteren compenseren dit cijfer niet.',
              new_value:'measures.potentie.totaal',
            },
            {
              value: 'vE_OrderPotencyCommercial',
              label: 'Commercieel',
              qlik: measures.potentie.commercieel,
              help:'De potentie is te behalen door 2 zaken te verbeteren. Of hogere tarieven of snellere uitvoering van de orders. Hogere tarieven is de commerciële potentie. Commerciële potentie wordt berekend door te kijken naar de voorcalculatie van de tijd en deze te toetsen aan de norm omzet/uur. Dus (voorcalculatie tijd x norm omzet/uur) - gerealiseerde omzet = Commerciële potentie.',
              new_value:'measures.potentie.commercieel',
            },
            {
              value: 'vE_OrderPotencyOperational',
              label: 'Operationeel',
              qlik: measures.potentie.operationeel,
              help:'De potentie is te behalen door 2 zaken te verbeteren. Of hogere tarieven of snellere uitvoering van de orders. Snellere uitvoering is de operationele potentie. Operationele potentie wordt berekend door te kijken naar de gerealiseerde tijd en deze te toetsen aan de voorcalculatie van de tijd en het verschil hiervan te vermenigvuldigen met de norm omzet/uur. Dus (gerealiseerde tijd - voorcalculatie tijd) x norm omzet/uur = Operationele potentie.',
              new_value:'measures.potentie.operationeel',
            },
          ]
        },
        {
          value: 'km',
          label: 'KM',
          children: [
            {
              value: 'vE_OrderKM',
              label: 'Totaal',
              qlik: measures.km.km,
              new_value:'measures.km.km',
              help: 'Geeft de totale kilometers op orderniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'vE_OrderKMFull',
              label: 'Beladen',
              qlik: measures.km.kmfull,
              help: 'Geeft de beladen kilometers op orderniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. ',
              new_value:'measures.km.kmfull',
            },
            {
              value: 'vE_OrderKMEmpty',
              label: 'Onbeladen',
              qlik: measures.km.kmempty,
              help: 'Geeft de onbeladen kilometers op orderniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. ',
              new_value:'measures.km.kmempty',
            },
            {
              value: 'vE_KPI_OrderOmzetPerKM',
              label: 'Omzet / KM',
              qlik: measures.km.omzetperkm,
              new_value:'measures.km.omzetperkm'
            },
            {
              value: 'vE_KPI_OrderOmzetPerKMFull',
              label: 'Omzet / beladen KM',
              qlik: measures.km.omzetperkmfull,
              new_value:'measures.km.omzetperkmfull'
            },
            {
              value: 'vE_KPI_OrderBelading',
              label: '% Beladen',
              qlik: measures.km.belading,
              new_value:'measures.km.belading'
            },
            {
              value: 'vE_OrderNormTurnoverKM',
              label: 'Norm Omzet / KM',
              qlik: measures.km.normomzetperkm,
              help: 'De geconfigureerde norm op orderniveau voor de Omzet / KM.',
              new_value:'measures.km.normomzetperkm'
            },
            {
              value: 'vE_OrderNormTurnoverKMFull',
              label: 'Norm Omzet / beladen KM',
              qlik: measures.km.normomzetperkmfull,
              new_value:'measures.km.normomzetperkmfull',
              help: 'De geconfigureerde norm op orderniveau voor de Omzet / beladen KM.'  
            },
          ]
        },
        {
          value: 'uren',
          label: 'Uren',
          children: [
            {
              value: 'vE_OrderUren',
              label: 'Totaal (Σ)',
              qlik: measures.uren.totaal,
              new_value:'measures.uren.totaal'
            },
            {
              value: 'vE_OrderUrenLoading',
              label: 'Laden (Σ)',
              qlik: measures.uren.loading,
              help: 'Het totaal aantal laaduren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. ',
              new_value:'measures.uren.loading' 
            },
            {
              value: 'vE_OrderUrenUnloading',
              label: 'Lossen (Σ)',
              qlik: measures.uren.unloading,
              new_value:'measures.uren.unloading',
              help: 'Het totaal aantal losuren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'vE_OrderUrenDriving',
              label: 'Rijden (Σ)',
              qlik: measures.uren.driving,
              new_value:'measures.uren.driving',
              help: 'Het totaal aantal rijuren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'vE_OrderUrenOther',
              label: 'Overig (Σ)',
              qlik: measures.uren.other,
              new_value:'measures.uren.other',
              help: 'Het totaal aantal overige uren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'vE_OrderUrenAvg',
              label: 'Totaal (avg)',
              qlik: measures.uren.avg_totaal,
              new_value:'measures.uren.avg_totaal',
              help: 'Het gemiddeld aantal uren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'vE_OrderUrenAvgLoading',
              label: 'Laden (avg)',
              qlik: measures.uren.avg_loading,
              new_value:'measures.uren.avg_loading',
              help: 'Het gemiddeld aantal laaduren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'vE_OrderUrenAvgUnloading',
              label: 'Lossen (avg)',
              qlik: measures.uren.avg_unloading,
              help: 'Het gemiddeld aantal losuren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. ',
              new_value:'measures.uren.avg_unloading',
            },
            {
              value: 'vE_OrderUrenAvgDriving',
              label: 'Rijden (avg)',
              qlik: measures.uren.avg_driving,
              new_value:'measures.uren.avg_driving',
              help: 'Het gemiddeld aantal rijuren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'vE_OrderUrenAvgOther',
              label: 'Overig (avg)',
              qlik: measures.uren.avg_other,
              new_value:'measures.uren.avg_other',
              help: 'Het gemiddeld aantal overige uren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
          ]
        },
        {
          value: 'vergelijkperiode',
          label: 'Voorgaand jaar',
          children: [
            {
              value: 'vE_OrderOmzetVorigJaar',
              label: 'Omzet',
              qlik: measures.previousyear.omzet,
              new_value:'measures.previousyear.omzet'
            },
            {
              value: 'vE_OrderAantalOrdersVorigJaar',
              label: 'Order',
              qlik: measures.previousyear.orders,
              new_value:'measures.previousyear.orders'
            },
            {
              value: 'vE_OrderAantalRittenVorigJaar',
              label: 'Ritten',
              qlik: measures.previousyear.ritten,
              new_value:'measures.previousyear.ritten'
            },
            {
              value: 'vE_KPI_OrderGoedkeurVorigJaar',
              label: 'OK',
              qlik: measures.previousyear.goedkeur,
              new_value:'measures.previousyear.goedkeur'
            },
  
            {
              value: 'vE_KPI_OrderNormMarginPercentVorigJaar',
              label: 'Norm Marge %',
              qlik: measures.previousyear.normmarge,
              new_value:'measures.previousyear.normmarge'
            },
            {
              value: 'vE_KPI_OrderNormMarginOverOmzetVorigJaar',
              label: 'Norm Marge',
              qlik: measures.previousyear.normmargeomzet,
              new_value:'measures.previousyear.normmargeomzet'
            },
            {
              value: 'vE_KPI_OrderOmzetPerUurVorigJaar',
              label: 'Omzet / Uur',
              qlik: measures.previousyear.omzetperuur,
              new_value:'measures.previousyear.omzetperuur'
            },
            {
              value: 'vE_KPI_OrderOmzetPerUurCombinatieVolgendeOrderVorigJaar',
              label: 'Omzet / Uur - rondrit',
              qlik: measures.previousyear.volgendeorder,
              new_value:'measures.previousyear.volgendeorder'
            },
            {
              value: 'vE_KPI_OrderTripOutsourcedVorigJaar',
              label: '% Uitbesteed',
              qlik: measures.previousyear.uitbesteed,
              new_value:'measures.previousyear.uitbesteed'
            },
            {
              value: 'vE_KPI_OrderCharterMargeVorigJaar',
              label: 'Derden Marge',
              qlik: measures.previousyear.chargermarge,
              new_value:'measures.previousyear.chargermarge'
            },
  
          ],
        },
        {
          value: 'persize',
          label: 'Per Eenheid',
          children: [
            {
              value: 'vE_OrderShipmentSize',
              label: 'Ton',
              qlik: measures.persize.shipmentsize,
              new_value:'measures.persize.shipmentsize',
              help: 'Het totaal aantal ton vanuit RoadRunner'
            },
            {
              value: 'vE_KPI_OrderOmzetPerZendingGroottePervE_OrderShipmentSize',
              label: 'Omzet Per Ton',
              qlik: measures.persize.omzet_shipmentsize,
              new_value:'measures.persize.omzet_shipmentsize',
              help: 'De totale omzet / gedeeld door het totale tonnage'
            },
            {
              value: 'vE_KPI_OrderLaadMinutenPerZendingGrootte',
              label: 'Laad minuten per Ton',
              qlik: measures.persize.loading_shipmentsize,
              new_value:'measures.persize.loading_shipmentsize',
              help: 'De toegekende laadtijd / gedeeld door het totale tonnage'
            },
            {
              value: 'vE_KPI_OrderLosMinutenPerZendingGrootte',
              label: 'Los minuten per ton',
              qlik: measures.persize.unloading_shipmentsize,
              new_value:'measures.persize.unloading_shipmentsize',
              help: 'De toegekende lostijd / gedeeld door het totale tonnage'
            },
            {
              value: 'vE_KPI_OrderMinutenPerZendingGrootte',
              label: 'Tijd per ton',
              qlik: measures.persize.time_shipmentsize,
              new_value:'measures.persize.time_shipmentsize',
              help: 'De toegekende tijd / gedeeld door het totale tonnage'          
            },
          ]
        },
      ]
    }
  ];
  